<template>
  <v-container fluid>

    <div>
      <h2>Qualification Plan Overview</h2>
    </div>

    <div v-if="activePlan">
      <qualification-plan-index-preview v-if="activePlan.status === 'in-process'" @onSubmit="onSubmit" :qualification-plan="activePlan" />
      <qualification-plan-index-review v-if="activePlan.status === 'review' || activePlan.status === 'signature'" :qualification-plan="activePlan" />
      <v-card class="mt-2" v-if="activePlan.status === 'finished'">
        <v-card-text>
          Qualification Plan for {{ activePlan.year }} finalized!
        </v-card-text>
      </v-card>
    </div>

    <div v-if="!activePlan" class="pa-10 d-flex justify-space-around">
      <div><span class="mr-2 text-subtitle-2">You currently have no active qualification plan: </span><v-btn color="green" small @click="createPlan">Create Plan</v-btn></div>
    </div>

    <div class="pt-5">
      <div>
        <h3 class="text-center">Past Qualification Plans</h3>
      </div>

      <v-data-table
        :headers="headers"
        :items="inactivePlans"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-dialog>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
              >
                Show
              </v-btn>
            </template>

            <div v-if="item">
              <qualification-plan-index-preview v-if="item.status === 'in-process'" @onSubmit="submitPlanWrapper(item)" :qualification-plan="item" />
              <qualification-plan-index-review v-if="item.status === 'review' || item.status === 'signature'" :qualification-plan="item" />
              <v-card class="mt-2" v-if="item.status === 'finished'">
                <v-card-text>
                  Qualification Plan for {{ item.year }} finalized!
                </v-card-text>
              </v-card>
            </div>
          </v-dialog>
        </template>

      </v-data-table>
    </div>


  </v-container>
</template>
<script>

import QualificationPlanIndexPreview from "./qualification-plan-index-preview";
import {defineComponent, onMounted, set, ref, reactive, computed, watchEffect} from "vue";
import {getAllCategories, getPlansForUser, submitPlan} from "@/modules/qualification-plan/api/api";
import {useGetters} from "vuex-composition-helpers";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import QualificationPlanIndexReview
  from "@/modules/qualification-plan/pages/qualification-plan-index/qualification-plan-index-review";

export default defineComponent({
  components: {QualificationPlanIndexReview, QualificationPlanIndexPreview},
  setup() {

    const plans = ref([]);
    const { user } = useGetters('auth', ['user']);
    onMounted(() => {
      getPlansForUser(user.value.id).then(result => {
        plans.value = result.asPaginationResult().membersData();
      })
    });

    const { isVisible } = useGetters('modal', ['isVisible']);
    watchEffect(() => {
      if(!isVisible.value) {
        getPlansForUser(user.value.id).then(result => {
          plans.value = result.asPaginationResult().membersData();
        })
      }
    });


    const currentYear = 2024;

    const activePlan = computed(() => plans.value.find(plan => plan['year'].toString() === currentYear.toString()) ?? null);
    const inactivePlans = computed(() => plans.value.filter(plan => plan['year'].toString() !== currentYear.toString()));

    const headers = [
      { text: 'Year', value: 'year' },
      { text: 'Total CP', value: 'totalCp' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ]

    const createPlan = () => {
      CLIENT.go(byApi('qualification_plans')).post({
        status: 'in-process',
        user: user.value.iri,
        year: currentYear
      }).then(() => {
        getPlansForUser(user.value.id).then(result => {
          plans.value = result.asPaginationResult().membersData();
        })
      }).catch(reason => {
        //TODO: Handle error
      })
    }

    const submitPlanWrapper = (item) => {
      submitPlan(item.id).then(result => {
        getPlansForUser(user.value.id).then(result => {
          plans.value = result.asPaginationResult().membersData();
        })
      });
    }

    const onSubmit = () => {
      submitPlan(activePlan.value.id).then(result => {
        getPlansForUser(user.value.id).then(result => {
          plans.value = result.asPaginationResult().membersData();
        })
      });
    }

    return {
      plans,
      activePlan,
      inactivePlans,
      headers,
      createPlan,
      onSubmit,
      submitPlanWrapper,
      user
    }
  }
})


</script>

<style scoped>

</style>
