
import {computed, defineComponent, onMounted, ref} from 'vue'
import QualificationPlanStatus
  from "@/modules/qualification-plan/component/qualification-plan-status/qualification-plan-status.vue";
import QualificationPlanSubmitDialog
  from "@/modules/qualification-plan/component/qualification-plan-submit-dialog/qualification-plan-submit-dialog.vue";
import {useGetters} from "vuex-composition-helpers";
import {getAllCategories, getPlansForUser} from "@/modules/qualification-plan/api/api";
import Intranet from "@/core/intranet";

export default defineComponent({
  components: {QualificationPlanSubmitDialog, QualificationPlanStatus},
  props: {
    qualificationPlan: {
      type: Object,
      required: true
    }
  },

  setup(props, {emit}) {
    const currentYear = new Date().getFullYear().toString();

    const submitDialog = ref(false);
    const onSubmit = () => {
      emit('onSubmit');
    }

    const isLoading = computed(() => {
      return categories.value.length == 0;
    });

    const categories = ref([]);
    onMounted(() => {
      getAllCategories().then(result => {
        categories.value = result.asPaginationResult().membersData();
      })
    });

    const cpCount = computed(() => {
      return props.qualificationPlan.activities
        .map(activity => activity.number * activity.cp)
        .reduce((previousValue, currentValue ) => previousValue + currentValue, 0);
    });

    const categoryActivities = computed(() => {
      return categories.value.map(category => {
        const categoryActivities = props.qualificationPlan.activities
          .filter(activity => activity.category === category['@id']);

        if(categoryActivities.length == 0) {
          return null;
        }

        return {
          ...(category as object),
          activities: categoryActivities
        }
      }).filter(x => !!x)
    });

    const hasAnyActivities = computed(() => {
      return props.qualificationPlan.activities.length > 0;
    });


    const openUploadDialog = (a) => {
      if(a.proofs.length === 0) {
        Intranet.modals().openModal('qualification-plan-proof-upload-modal', {activity: a})
      }
    }

    return {
      categories,
      categoryActivities,
      isLoading,
      hasAnyActivities,
      currentYear,
      submitDialog,
      onSubmit,
      cpCount, openUploadDialog
    }
  },
  methods: {
    goToEdit() {
      this.$router.push({name: 'qualification-plan-edit', params: {id: this.qualificationPlan.id}});
    }
  }
});
