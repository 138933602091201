<template>
  <div>
    <v-card class="mt-2">

      <div>
        <h3 class="text-center">Active Qualification Plan - Year {{ currentYear }}</h3>
      </div>

      <v-card-text>

        <div>
          <qualification-plan-status :status="qualificationPlan.status"/>
        </div>

        <div class="text-h5 text-center mt-6">
          Your Qualification will be reviewed shortly
        </div>

        <div v-if="hasAnyActivities">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left text-h5" style="width: 70%">
                  Activity
                </th>
                <th class="text-center text-h5" style="width: 10%">
                  Quantity
                </th>
                <th class="text-center text-h5" style="width: 10%">
                  Base CP
                </th>
                <th class="text-center text-h5" style="width: 10%">

                </th>
              </tr>
              </thead>
            </template>
          </v-simple-table>

          <div v-for="category in categoryActivities">
            <div class=" ml-2 mt-2 text-subtitle-1 text-decoration-underline">{{ category.displayName }}</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr
                    v-for="item in category.activities"
                    :key="item.description"
                >
                  <td class="text-left" style="width: 70%">{{ item.description }}</td>
                  <td class="text-center" style="width: 10%">{{ item.number }}</td>
                  <td class="text-center" style="width: 10%">{{ item.cp }}</td>
                  <td class="text-right" style="width: 10%">
                    <v-btn icon  @click="openUploadDialog(item)"><v-icon v-if="item.proofs.length === 0">mdi-folder-arrow-up-outline</v-icon><v-icon v-else>mdi-check</v-icon></v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="d-flex pt-4 pr-2">
            <v-spacer />
            <h3>Total CP: <span>{{ cpCount }}</span></h3>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="" style="display: flex; justify-content: end; gap: 8px">
        <v-btn v-if="!hasSignedUploaded" @click="openSignedUploadDialog">Upload signed Plan</v-btn>
        <v-btn v-else disabled @click="openSignedUploadDialog">Plan already uploaded</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import QualificationPlanStatus
  from "@/modules/qualification-plan/component/qualification-plan-status/qualification-plan-status";
import {computed, onMounted, ref, watchEffect} from "vue";
import {getAllCategories} from "@/modules/qualification-plan/api/api";
import Intranet from "@/core/intranet";
import {useGetters} from "vuex-composition-helpers";
import {CLIENT} from "@/api/client";

export default {
  name: "qualification-plan-index-review",
  components: {QualificationPlanStatus},
  props: {
    qualificationPlan: Object
  },
  setup(props, {emit}) {
    const currentYear = new Date().getFullYear().toString();

    const categories = ref([]);
    onMounted(() => {
      getAllCategories().then(result => {
        categories.value = result.asPaginationResult().membersData();
      })
    });

    const { isVisible } = useGetters('modal', ['isVisible']);

    const hasAnyActivities = computed(() => {
      return props.qualificationPlan.activities.length > 0;
    });

    const cpCount = computed(() => {
      return props.qualificationPlan.activities
          .map(activity => activity.number * activity.cp)
          .reduce((previousValue, currentValue ) => previousValue + currentValue, 0);
    });

    const categoryActivities = computed(() => {
      return categories.value.map(category => {
        const categoryActivities = props.qualificationPlan.activities
            .filter(activity => activity.category === category['@id']);

        if(categoryActivities.length === 0) {
          return null;
        }

        return {
          ...(category),
          activities: categoryActivities
        }
      }).filter(x => !!x)
    });

    const openUploadDialog = (a) => {
      if(a.proofs.length === 0) {
        Intranet.modals().openModal('qualification-plan-proof-upload-modal', {activity: a})
      }
    }

    const openSignedUploadDialog = () => {
      Intranet.modals().openModal('qualification-plan-signed-upload-modal', {plan: props.qualificationPlan})
    }

    const hasSignedUploaded = computed(() => {
      return props.qualificationPlan.signedFiles.length > 0;
    });

    return {
      categories, hasAnyActivities, cpCount, categoryActivities,
      currentYear, openUploadDialog, openSignedUploadDialog, hasSignedUploaded
    }
  }
}
</script>

<style scoped>

</style>
