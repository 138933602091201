<template>

  <v-dialog
    v-model="showDialog"
    persistent
    max-width="700"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="hasNothing"
      >
        Submit
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Do you really want to submit your qualification plan?
      </v-card-title>
      <v-card-text>
        <div>You are about to submit your qualification plan for {{ qualificationPlan.year }}.</div>
        <div>You have {{ activityCount }} activities which sums up to {{ cpCount }} CP.</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="showDialog = false"
        >
          No
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="submitDialog"
        >
          Agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, defineComponent, ref} from "vue";

export default defineComponent({
  name: "qualification-plan-submit-dialog",
  props: {
    qualificationPlan: Object
  },
  setup(props, {emit}) {
    const showDialog = ref(false);
    const submitDialog = () => {
      showDialog.value = false;
      emit('onSubmit');
    };

    const activityCount = computed(() => {
      return props.qualificationPlan.activities.length;
    });

    const cpCount = computed(() => {
      return props.qualificationPlan.activities
        .map(activity => activity.number * activity.cp)
        .reduce((previousValue, currentValue ) => previousValue + currentValue, 0);
    });

    const hasNothing = computed(() => {
      return cpCount.value === 0 && activityCount.value === 0;
    });

    return {
      showDialog,
      submitDialog,
      activityCount,
      cpCount,
      hasNothing
    }
  }
});
</script>

<style scoped>

</style>
