import { render, staticRenderFns } from "./qualification-plan-index-review.vue?vue&type=template&id=54281de3&scoped=true&"
import script from "./qualification-plan-index-review.vue?vue&type=script&lang=js&"
export * from "./qualification-plan-index-review.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54281de3",
  null
  
)

export default component.exports